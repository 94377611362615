import { FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { IRecipeThumb } from '@modules/collections/domain/models/newCollection.model';
import { analytics } from '@shared/utils/segment.utils';
import { CollectionLink, Container, ContentWrapper, Description, MenuAmount, Name, RecipeItem, RecipeList, Title } from './Cover.style';
interface Props {
  name: string;
  menuAmount: number;
  imageUrl: string;
  videoUrl: string;
  slug: string;
  recipeList: IRecipeThumb[];
  nameSize?: number;
  nameLineHeight?: number;
  menuAmountSize?: number;
  description?: string;
}
const CollectionCover: FC<Props> = ({
  menuAmount,
  name,
  imageUrl,
  videoUrl,
  slug,
  recipeList,
  nameSize,
  nameLineHeight,
  menuAmountSize,
  description
}: Props) => {
  const router = useRouter();
  return <Container $imageUrl={imageUrl}>
            <div className="overlay-cover" />
            {videoUrl && <video className="background-vdo" playsInline autoPlay muted loop>
                    <source src={videoUrl} type="video/mp4" />
                </video>}
            <ContentWrapper>
                <CollectionLink href={`/collection/${slug}`} aria-label="collection detail" onClick={() => {
        analytics.track('Collection Thumbnail L Click', {
          event: 'collection_thumbnail_l_click'
        });
        analytics.track('Collection Viewed', {
          collection_name: name
        });
      }} />
                <Title>
                    <MenuAmount $menuAmountSize={menuAmountSize}>
                        {menuAmount} เมนู
                    </MenuAmount>
                    <Name $nameSize={nameSize} $nameLineHeight={nameLineHeight}>
                        {name}
                    </Name>
                    {description && <Description>{description}</Description>}
                </Title>
                <RecipeList>
                    {recipeList?.map((recipe, index) => {
          return <RecipeItem key={index} onClick={() => {
            analytics.track('Collection Thumbnail S Click', {
              event: 'collection_thumbnail_s_click'
            });
            analytics.track('Recipe Viewed', {
              recipe_name: recipe.name,
              ingredient: ''
            });
            router.push(`/recipe/${recipe.slug}`);
          }}>
                                <Image src={recipe.imageUrl || '/images/default/recipe.jpg'} alt={name} onError={({
              currentTarget
            }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/default/recipe.jpg';
            }} loading="lazy" placeholder="blur" blurDataURL="/images/default/recipe.jpg" width={106} height={106} />
                            </RecipeItem>;
        })}
                </RecipeList>
            </ContentWrapper>
        </Container>;
};
export default CollectionCover;